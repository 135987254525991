import { FormattedMessage, FormattedNumberParts } from 'react-intl';

import { useAppContext } from '../../context/AppContext';
import { useCountry } from '../../context/CountryContext';
import useCurrency from '../../hooks/useCurrency';
import { Order } from '../../types';
import { formatPrice, MAX_AMOUNTS_PER_COUNTRY } from '../../utils/shipping';

const ShippingPriceDisplay = ({ order }: { order: Order }) => {
    const { brand, countries, isGlobalE } = useAppContext();
    const { country } = useCountry();
    const currency = useCurrency();
    const price = order.totalPrice! / 100;

    const countryData = countries?.find(c => c.label?.toLowerCase() === country.countryISO?.toLowerCase());

    if (!order.shippingMode && !order.lastMinuteShippingMode) {
        const freeShippingThreshold = isGlobalE
            ? MAX_AMOUNTS_PER_COUNTRY[brand][country.countryISO!]?.value
            : countryData?.freeShippingThreshold;

        if (freeShippingThreshold === undefined) {
            return null;
        }

        const currencyCode = isGlobalE ? country.currencyCode : currency?.code;

        return price < freeShippingThreshold || isGlobalE ? (
            <FormattedMessage
                id="cart.total.shipping_free_for_amount"
                values={{
                    country: country.label,
                    amount: (
                        <FormattedNumberParts
                            value={freeShippingThreshold}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currency={currencyCode}
                            minimumFractionDigits={0}
                        >
                            {parts => <>{formatPrice(parts, currencyCode)}</>}
                        </FormattedNumberParts>
                    ),
                }}
            />
        ) : (
            <FormattedMessage id="cart.total.shipping_free" />
        );
    } else {
        return order.shippingPrice === 0 ? (
            <FormattedMessage id="cart.total.shipping_free" />
        ) : (
            <>{order.shippingDisplayPrice}</>
        );
    }
};

export default ShippingPriceDisplay;
