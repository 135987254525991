import classnames from 'classnames';
import { Fragment, ReactNode } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router';

import { Tooltip } from '@sezane/front-components';
import { useAppContext } from '../../context/AppContext';
import { useCountry } from '../../context/CountryContext';
import { useUrl } from '../../hooks/useUrl';
import { Order, OrderProduct } from '../../types/api';
import { BRANDS } from '../../utils/brand';
import { hasNormalDeliveryProducts, hasQuickDeliveryProducts } from '../../utils/order';
import Discount from '../Discount/Discount';
import ErrorFallback from '../Errors/ErrorFallback';
import { WritingIcon } from '../Icons';
import GiftIcon from '../Icons/Gift';
import OrderItem from '../Order/OrderItem';
import { FETCH_PROMOS_KEY } from '../Promos/Promos';
import { FETCH_SHIPPING_MODES_KEY } from '../Shipping/ShippingMode';
import Charges from './Charges';
import ShippingPriceDisplay from './ShippingPriceDisplay';
import TotalDiscount from './TotalDiscount';

const tooltipTitle = {
    [BRANDS.sezane]: defineMessages<string>({
        au: { id: 'cart.order.tooltip_label.au.sezane' },
        ca: { id: 'cart.order.tooltip_label.ca.sezane' },
        de: { id: 'cart.order.tooltip_label.de.sezane' },
        en: { id: 'cart.order.tooltip_label.en.sezane' },
        es: { id: 'cart.order.tooltip_label.es.sezane' },
        eu: { id: 'cart.order.tooltip_label.eu.sezane' },
        fr: { id: 'cart.order.tooltip_label.fr.sezane' },
        it: { id: 'cart.order.tooltip_label.it.sezane' },
        jp: { id: 'cart.order.tooltip_label.jp.sezane' },
        nl: { id: 'cart.order.tooltip_label.nl.sezane' },
        pt: { id: 'cart.order.tooltip_label.pt.sezane' },
        us: { id: 'cart.order.tooltip_label.us.sezane' },
    }),
    [BRANDS.octobre]: defineMessages<string>({
        au: { id: 'cart.order.tooltip_label.au.octobre' },
        ca: { id: 'cart.order.tooltip_label.ca.octobre' },
        de: { id: 'cart.order.tooltip_label.de.octobre' },
        en: { id: 'cart.order.tooltip_label.en.octobre' },
        es: { id: 'cart.order.tooltip_label.es.octobre' },
        eu: { id: 'cart.order.tooltip_label.eu.octobre' },
        fr: { id: 'cart.order.tooltip_label.fr.octobre' },
        it: { id: 'cart.order.tooltip_label.it.octobre' },
        jp: { id: 'cart.order.tooltip_label.jp.octobre' },
        nl: { id: 'cart.order.tooltip_label.nl.octobre' },
        pt: { id: 'cart.order.tooltip_label.pt.octobre' },
        us: { id: 'cart.order.tooltip_label.us.octobre' },
    }),
};

const tooltipContent = {
    [BRANDS.sezane]: defineMessages<string>({
        au: { id: 'cart.order.tooltip_content.au.sezane' },
        ca: { id: 'cart.order.tooltip_content.ca.sezane' },
        de: { id: 'cart.order.tooltip_content.de.sezane' },
        en: { id: 'cart.order.tooltip_content.en.sezane' },
        es: { id: 'cart.order.tooltip_content.es.sezane' },
        eu: { id: 'cart.order.tooltip_content.eu.sezane' },
        fr: { id: 'cart.order.tooltip_content.fr.sezane' },
        it: { id: 'cart.order.tooltip_content.it.sezane' },
        jp: { id: 'cart.order.tooltip_content.jp.sezane' },
        nl: { id: 'cart.order.tooltip_content.nl.sezane' },
        pt: { id: 'cart.order.tooltip_content.pt.sezane' },
        us: { id: 'cart.order.tooltip_content.us.sezane' },
    }),
    [BRANDS.octobre]: defineMessages<string>({
        au: { id: 'cart.order.tooltip_content.au.octobre' },
        ca: { id: 'cart.order.tooltip_content.ca.octobre' },
        de: { id: 'cart.order.tooltip_content.de.octobre' },
        en: { id: 'cart.order.tooltip_content.en.octobre' },
        es: { id: 'cart.order.tooltip_content.es.octobre' },
        eu: { id: 'cart.order.tooltip_content.eu.octobre' },
        fr: { id: 'cart.order.tooltip_content.fr.octobre' },
        it: { id: 'cart.order.tooltip_content.it.octobre' },
        jp: { id: 'cart.order.tooltip_content.jp.octobre' },
        nl: { id: 'cart.order.tooltip_content.nl.octobre' },
        pt: { id: 'cart.order.tooltip_content.pt.octobre' },
        us: { id: 'cart.order.tooltip_content.us.octobre' },
    }),
};

interface TotalProps {
    actionEl?: ReactNode;
    className?: string;
    displayDiscountCheckbox?: boolean;
    displayProducts?: boolean;
    order: Order;
    setDisplayOptionsModal: (display: boolean) => void;
    setCustomisationProduct: (product: OrderProduct | undefined) => void;
    setEcardMessageProduct: (product: OrderProduct | undefined) => void;
}

const Total = ({
    actionEl,
    className,
    displayDiscountCheckbox,
    displayProducts,
    order,
    setDisplayOptionsModal,
    setCustomisationProduct,
    setEcardMessageProduct,
}: TotalProps) => {
    const { routes } = useUrl();
    const { pathname } = useLocation();
    const isPayment = pathname === routes.payment;
    const queryClient = useQueryClient();
    const { brand, site } = useAppContext();
    const { country } = useCountry();
    const intl = useIntl();

    const quickDeliveryProducts = order.products?.filter(hasQuickDeliveryProducts);
    const normalDeliveryProducts = order.products?.filter(hasNormalDeliveryProducts);
    const giftDeliveryProducts = order.products?.filter(({ gift }) => gift);

    const hasQuickDelivery = quickDeliveryProducts && quickDeliveryProducts.length > 0;
    const hasNormalDelivery = normalDeliveryProducts && normalDeliveryProducts?.length > 0;
    const hasGifts = giftDeliveryProducts && giftDeliveryProducts.length > 0;
    const hasPackaging = order.packaging && order.packaging?.length > 0;

    const freeShipping =
        process.env.REACT_APP_FREE_SHIPPING === 'true' && [routes.cart, routes.shipping].includes(pathname);

    return (
        <div className={classnames('c-box c-box--outline', className)}>
            {displayProducts && (
                <>
                    <div className="c-box__inner c-order-checkout c-order-checkout--total u-p-sm-bis@sm">
                        {hasQuickDelivery && (
                            <div className={hasNormalDelivery || hasGifts || hasPackaging ? 'u-mb-md' : ''}>
                                <div className="c-order-checkout__header">
                                    <FormattedMessage id="cart.list.quick_delivery" />
                                </div>
                                <div className="c-order-checkout__body">
                                    {quickDeliveryProducts.map((product, index) => {
                                        const nextProductSeparation =
                                            quickDeliveryProducts[index + 1] &&
                                            quickDeliveryProducts[index + 1]?.multiProducts?.length === 0;

                                        return (
                                            <Fragment key={product.orderItemProductId}>
                                                <OrderItem
                                                    key={product.orderItemProductId}
                                                    product={product}
                                                    display="small"
                                                    setCustomisationProduct={setCustomisationProduct}
                                                    setEcardMessageProduct={setEcardMessageProduct}
                                                />
                                                {nextProductSeparation && <hr className="c-separator u-m-sm" />}
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {hasNormalDelivery && (
                            <div className={hasGifts || hasPackaging ? 'u-mb-md' : ''}>
                                <div className="c-order-checkout__header">
                                    {quickDeliveryProducts && quickDeliveryProducts.length > 0 ? (
                                        <FormattedMessage id="cart.list.standard_delivery" />
                                    ) : (
                                        <FormattedMessage id="cart.list.shipping" />
                                    )}
                                </div>
                                <div className="c-order-checkout__body">
                                    {normalDeliveryProducts?.map((product, index) => {
                                        const nextProductSeparation =
                                            normalDeliveryProducts[index + 1] &&
                                            normalDeliveryProducts[index + 1]?.multiProducts?.length === 0;

                                        return (
                                            <Fragment key={product.orderItemProductId}>
                                                <OrderItem
                                                    product={product}
                                                    display="small"
                                                    setCustomisationProduct={setCustomisationProduct}
                                                    setEcardMessageProduct={setEcardMessageProduct}
                                                />
                                                {nextProductSeparation && <hr className="c-separator u-m-sm" />}
                                            </Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {hasGifts && (
                            <div className={hasPackaging ? 'u-mb-md' : ''}>
                                <div className="c-order-checkout__header">
                                    <p>
                                        <span className="u-mr-xs">
                                            <GiftIcon height={12} />
                                        </span>
                                        <FormattedMessage id="cart.list.gifts" />
                                    </p>
                                </div>
                                <div className="c-order-checkout__body">
                                    {giftDeliveryProducts.map((product, index) => (
                                        <Fragment key={product.orderItemProductId}>
                                            <OrderItem
                                                product={product}
                                                display="small"
                                                setCustomisationProduct={setCustomisationProduct}
                                                setEcardMessageProduct={setEcardMessageProduct}
                                            />
                                            {index < giftDeliveryProducts.length - 1 && (
                                                <hr className="c-separator u-m-sm" />
                                            )}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        )}
                        {hasPackaging && (
                            <button
                                className="u-btn-unstyled c-order-checkout__customization"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setDisplayOptionsModal && setDisplayOptionsModal(true);
                                }}
                            >
                                <FormattedMessage id="cart.list.options" />
                                <i className="c-icon u-text-icon-xxs u-ml-xs">
                                    <WritingIcon />
                                </i>
                            </button>
                        )}
                    </div>
                    <hr className="c-separator u-m-n" />
                </>
            )}
            <div className="c-box__inner u-p-md@sm">
                <ul className="u-list-unstyled c-total-checkout">
                    <li className="o-grid o-grid--gutter-sm u-flex-nowrap u-flex-main-between">
                        <div className="o-grid__col u-flex-item-auto">
                            <FormattedMessage id="cart.total.subtotal" />
                        </div>
                        <div className="o-grid__col u-text-right">
                            <span className="c-total-checkout__value">{order.subTotalDisplayPrice}</span>
                        </div>
                    </li>
                    <li className="o-grid o-grid--gutter-sm u-flex-nowrap u-flex-main-between u-mt-xs-sm">
                        <div className="o-grid__col">
                            <FormattedMessage id="cart.total.shipping" />
                        </div>
                        <div className="o-grid__col u-text-right">
                            <span className="c-total-checkout__value">
                                {freeShipping ? (
                                    <FormattedMessage id="cart.total.free_shipping" />
                                ) : (
                                    <ShippingPriceDisplay order={order} />
                                )}
                            </span>
                        </div>
                    </li>

                    {order.vouchers?.map(voucher => (
                        <TotalDiscount key={voucher.id} discount={voucher} type="voucher" />
                    ))}
                    {order.userCredits?.map(userCredit => (
                        <TotalDiscount key={userCredit.id} discount={userCredit} type="userCredit" />
                    ))}
                    {order.ecards?.map(ecard => (
                        <TotalDiscount key={ecard.id} discount={ecard} type="ecards" />
                    ))}
                    {order.promotionalCampaigns?.map(promo => (
                        <TotalDiscount
                            key={promo.id}
                            discount={promo}
                            type="promo"
                            onRemove={() => {
                                queryClient.invalidateQueries(FETCH_PROMOS_KEY);
                                queryClient.invalidateQueries(FETCH_SHIPPING_MODES_KEY);
                            }}
                        />
                    ))}

                    {!!order.amountSalesTax && (
                        <li className="o-grid o-grid--gutter-sm u-flex-nowrap u-flex-main-between u-mt-xs-sm">
                            <div className="o-grid__col">
                                <FormattedMessage id="cart.total.sales_tax" />
                            </div>
                            <div className="o-grid__col u-text-right">
                                <span className="c-total-checkout__value">{order.amountSalesTaxDisplay}</span>
                            </div>
                        </li>
                    )}
                    {!!order.amountDutiesAndOtherFees && (
                        <li className="o-grid o-grid--gutter-sm u-flex-nowrap u-flex-main-between u-mt-xs-sm">
                            <div className="o-grid__col">
                                <FormattedMessage id="cart.total.duties" />
                            </div>
                            <div className="o-grid__col u-text-right">
                                <span className="c-total-checkout__value">{order.amountDutiesAndOtherFeesDisplay}</span>
                            </div>
                        </li>
                    )}

                    <li className="o-grid o-grid--gutter-sm u-flex-nowrap u-flex-main-between u-mt-sm">
                        <div className="o-grid__col u-flex u-flex-item-auto u-flex-cross-baseline">
                            <span className="c-total-checkout__text-highlighted">
                                <FormattedMessage id="cart.total.total" />
                            </span>
                            {((order.totalVat && order.totalVat > 0) || isPayment) && order.totalVatDisplayed && (
                                <span className="c-total-checkout__text-additional u-ml-xxs-bis u-text-normal">
                                    <FormattedMessage id="cart.total.taxes" values={{ tax: order.totalVatDisplayed }} />
                                </span>
                            )}
                        </div>
                        <div className="o-grid__col u-text-right">
                            <span className="c-total-checkout__text-highlighted">{order.totalDisplayPrice}</span>
                        </div>
                    </li>

                    <Charges
                        element="li"
                        className="c-alert-order u-mt-sm"
                        country={country}
                        subTotalPrice={order.subTotalPrice}
                    />
                </ul>
            </div>
            {displayProducts ? (
                <>
                    <hr className="c-separator u-m-n" />
                    <div className="c-box__inner u-pt-md@sm u-pb-md@sm u-pl-sm-bis@sm u-pr-sm-bis@sm">
                        {actionEl}
                        <Discount hasCheckbox={displayDiscountCheckbox} />
                    </div>
                </>
            ) : (
                <div className="c-box__inner u-pt-n u-pb-sm-bis u-pl-sm-bis@sm u-pr-sm-bis@sm">
                    {actionEl}
                    <Discount hasCheckbox={displayDiscountCheckbox} />
                    {site.code && tooltipTitle[brand][site.code] && (
                        <Tooltip
                            id="cart_order_tooltip"
                            className="u-block u-mt-md c-tooltip"
                            iconClassName="c-tooltip__trigger--label"
                            bubbleClassName="c-tooltip__bubble--multiline-full"
                            labelClassName="c-tooltip__label"
                            label={
                                freeShipping
                                    ? intl.formatMessage({ id: 'cart.total.free_shipping' })
                                    : intl.formatMessage({ id: tooltipTitle[brand][site.code].id })
                            }
                            content={
                                <p>
                                    <FormattedMessage id={tooltipContent[brand][site.code].id} />
                                </p>
                            }
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default withErrorBoundary(Total, { FallbackComponent: ErrorFallback });
