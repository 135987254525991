import { FieldPath } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { FormData } from '../components/Shipping/types/ShippingForm';
import { Address, ShippingMode, UserAddress } from '../types/api';
import { BRANDS } from './brand';

export const MAX_AMOUNTS_PER_COUNTRY: Record<BRANDS, Record<string, { name: string; value: number }>> = {
    [BRANDS.sezane]: {
        AD: { name: 'Andorre', value: 250 },
        AE: { name: 'Émirats Arabes Unis', value: 750 },
        AR: { name: 'Argentine', value: 180000 },
        AT: { name: 'Autriche', value: 0 },
        AU: { name: 'Australie', value: 200 },
        BA: { name: 'Bosnie-Herzégovine', value: 390 },
        BE: { name: 'Belgique', value: 200 },
        BG: { name: 'Bulgarie', value: 250 },
        BH: { name: 'Bahreïn', value: 80 },
        BL: { name: 'Saint-Barthélemy', value: 200 },
        BR: { name: 'Brésil', value: 1000 },
        BY: { name: 'Bielorussie', value: 690 },
        CA: { name: 'Canada', value: 250 },
        CH: { name: 'Suisse', value: 200 },
        CI: { name: "Côte-d'Ivoire", value: 130000 },
        CL: { name: 'Chili', value: 185000 },
        CN: { name: 'Chine', value: 1500 },
        CO: { name: 'Colombie', value: 1000000 },
        CY: { name: 'Chypre', value: 250 },
        CZ: { name: 'République Tchèque', value: 250 },
        DE: { name: 'Allemagne', value: 200 },
        DK: { name: 'Danemark', value: 1500 },
        EE: { name: 'Estonie', value: 250 },
        ES: { name: 'Espagne', value: 150 },
        FI: { name: 'Finlande', value: 200 },
        FR: { name: 'France', value: 200 },
        GB: { name: 'Royaume-Uni', value: 200 },
        GE: { name: 'Géorgie', value: 550 },
        GF: { name: 'Guyane française', value: 200 },
        GG: { name: 'Guernesey', value: 200 },
        GP: { name: 'Guadeloupe', value: 200 },
        GR: { name: 'Grèce', value: 250 },
        HK: { name: 'Hong Kong', value: 200 },
        HR: { name: 'Croatie', value: 200 },
        HU: { name: 'Hongrie', value: 200 },
        ID: { name: 'Indonésie', value: 3000000 },
        IE: { name: 'Irlande', value: 200 },
        IL: { name: 'Israël', value: 800 },
        IM: { name: 'Île de Man', value: 200 },
        IN: { name: 'Inde', value: 15500 },
        IS: { name: 'Islande', value: 30000 },
        IT: { name: 'Italie', value: 200 },
        JE: { name: 'Jersey', value: 200 },
        JP: { name: 'Japon', value: 25000 },
        KR: { name: 'Corée du Sud, République', value: 250000 },
        KW: { name: 'Koweït', value: 65 },
        LB: { name: 'Liban', value: 3200000 },
        LI: { name: 'Liechtenstein', value: 200 },
        LT: { name: 'Lituanie', value: 250 },
        LU: { name: 'Luxembourg', value: 200 },
        LV: { name: 'Lettonie', value: 250 },
        MA: { name: 'Maroc', value: 2000 },
        MC: { name: 'Monaco', value: 200 },
        MF: { name: 'Saint-Martin (partie française)', value: 200 },
        MO: { name: 'Macao', value: 1550 },
        MQ: { name: 'Martinique', value: 200 },
        MT: { name: 'Malte', value: 250 },
        MU: { name: 'Maurice', value: 9000 },
        MX: { name: 'Mexique', value: 3000 },
        MY: { name: 'Malaisie', value: 900 },
        NC: { name: 'Nouvelle-Calédonie', value: 200 },
        NG: { name: 'Nigéria', value: 320000 },
        NL: { name: 'Pays-Bas', value: 200 },
        NO: { name: 'Norvège', value: 2000 },
        NZ: { name: 'Nouvelle-Zélande', value: 350 },
        OM: { name: 'Oman', value: 80 },
        PA: { name: 'Panama', value: 300 },
        PE: { name: 'Pérou', value: 800 },
        PF: { name: 'Polynésie française', value: 200 },
        PH: { name: 'Philippines', value: 10500 },
        PL: { name: 'Pologne', value: 200 },
        PR: { name: 'Porto Rico', value: 300 },
        PT: { name: 'Portugal', value: 200 },
        QA: { name: 'Qatar', value: 750 },
        RE: { name: 'Réunion', value: 200 },
        RO: { name: 'Roumanie', value: 200 },
        RS: { name: 'Serbie', value: 20500 },
        RU: { name: 'Russie', value: 24000 },
        SA: { name: 'Arabie Saoudite', value: 800 },
        SE: { name: 'Suède', value: 200 },
        SG: { name: 'Singapour', value: 250 },
        SI: { name: 'Slovénie', value: 250 },
        SK: { name: 'Slovaquie', value: 250 },
        SN: { name: 'Sénégal', value: 130000 },
        TH: { name: 'Thaïlande', value: 7000 },
        TN: { name: 'Tunisie', value: 650 },
        TR: { name: 'Turquie', value: 3500 },
        TW: { name: 'Taïwan', value: 6500 },
        UA: { name: 'Ukraine', value: 8500 },
        US: { name: "États-Unis d'Amérique", value: 200 },
        UY: { name: 'Uruguay', value: 8000 },
        VC: { name: 'Saint-Vincent et les Grenadines', value: 550 },
        // VE: { name: 'Vénézuela', value: 3100 },
        VN: { name: 'Vietnam', value: 5000000 },
        WF: { name: 'Wallis et Futuna', value: 200 },
        YT: { name: 'Mayotte', value: 200 },
        ZA: { name: 'Afrique du Sud', value: 3500 },
    },
    [BRANDS.octobre]: {
        AD: { name: 'Andorre', value: 250 },
        AE: { name: 'Émirats Arabes Unis', value: 1100 },
        AR: { name: 'Argentine', value: 30000 },
        AT: { name: 'Autriche', value: 200 },
        AU: { name: 'Australie', value: 300 },
        BA: { name: 'Bosnie-Herzégovine', value: 500 },
        BE: { name: 'Belgique', value: 200 },
        BG: { name: 'Bulgarie', value: 250 },
        BH: { name: 'Bahreïn', value: 115 },
        BL: { name: 'Saint-Barthélemy', value: 200 },
        BR: { name: 'Brésil', value: 1700 },
        BY: { name: 'Bielorussie', value: 690 },
        CA: { name: 'Canada', value: 250 },
        CH: { name: 'Suisse', value: 200 },
        CI: { name: "Côte-d'Ivoire", value: 165000 },
        CL: { name: 'Chili', value: 245000 },
        CN: { name: 'Chine', value: 250 },
        CO: { name: 'Colombie', value: 1165500 },
        CY: { name: 'Chypre', value: 250 },
        CZ: { name: 'République Tchèque', value: 250 },
        DE: { name: 'Allemagne', value: 200 },
        DK: { name: 'Danemark', value: 1500 },
        EE: { name: 'Estonie', value: 250 },
        ES: { name: 'Espagne', value: 200 },
        FI: { name: 'Finlande', value: 200 },
        FR: { name: 'France', value: 200 },
        GB: { name: 'Royaume-Uni', value: 200 },
        GE: { name: 'Géorgie', value: 1000 },
        GF: { name: 'Guyane française', value: 200 },
        GG: { name: 'Guernesey', value: 200 },
        GP: { name: 'Guadeloupe', value: 200 },
        GR: { name: 'Grèce', value: 250 },
        HK: { name: 'Hong Kong', value: 200 },
        HR: { name: 'Croatie', value: 200 },
        HU: { name: 'Hongrie', value: 200 },
        ID: { name: 'Indonésie', value: 4500000 },
        IE: { name: 'Irlande', value: 200 },
        IL: { name: 'Israël', value: 1000 },
        IM: { name: 'Île de Man', value: 200 },
        IN: { name: 'Inde', value: 22500 },
        IS: { name: 'Islande', value: 200 },
        IT: { name: 'Italie', value: 200 },
        JE: { name: 'Jersey', value: 200 },
        JP: { name: 'Japon', value: 250 },
        KR: { name: 'Corée du Sud, République', value: 200000 },
        KW: { name: 'Koweït', value: 90 },
        LB: { name: 'Liban', value: 450000 },
        LI: { name: 'Liechtenstein', value: 300 },
        LT: { name: 'Lituanie', value: 250 },
        LU: { name: 'Luxembourg', value: 200 },
        LV: { name: 'Lettonie', value: 250 },
        MA: { name: 'Maroc', value: 2800 },
        MC: { name: 'Monaco', value: 200 },
        MF: { name: 'Saint-Martin (partie française)', value: 200 },
        MO: { name: 'Macao', value: 2500 },
        MQ: { name: 'Martinique', value: 200 },
        MT: { name: 'Malte', value: 250 },
        MU: { name: 'Maurice', value: 12500 },
        MX: { name: 'Mexique', value: 5000 },
        MY: { name: 'Malaisie', value: 1300 },
        NC: { name: 'Nouvelle-Calédonie', value: 200 },
        NG: { name: 'Nigéria', value: 125000 },
        NL: { name: 'Pays-Bas', value: 200 },
        NO: { name: 'Norvège', value: 200 },
        NZ: { name: 'Nouvelle-Zélande', value: 450 },
        OM: { name: 'Oman', value: 120 },
        PA: { name: 'Panama', value: 300 },
        PE: { name: 'Pérou', value: 1200 },
        PF: { name: 'Polynésie française', value: 200 },
        PH: { name: 'Philippines', value: 15000 },
        PL: { name: 'Pologne', value: 200 },
        PR: { name: 'Porto Rico', value: 300 },
        PT: { name: 'Portugal', value: 200 },
        QA: { name: 'Qatar', value: 1130 },
        RE: { name: 'Réunion', value: 200 },
        RO: { name: 'Roumanie', value: 200 },
        RS: { name: 'Serbie', value: 30000 },
        RU: { name: 'Russie', value: 24000 },
        SA: { name: 'Arabie Saoudite', value: 1100 },
        SE: { name: 'Suède', value: 200 },
        SG: { name: 'Singapour', value: 250 },
        SI: { name: 'Slovénie', value: 250 },
        SK: { name: 'Slovaquie', value: 250 },
        SN: { name: 'Sénégal', value: 160000 },
        TH: { name: 'Thaïlande', value: 10000 },
        TN: { name: 'Tunisie', value: 850 },
        TR: { name: 'Turquie', value: 4500 },
        TW: { name: 'Taïwan', value: 7000 },
        UA: { name: 'Ukraine', value: 8500 },
        US: { name: "États-Unis d'Amérique", value: 200 },
        UY: { name: 'Uruguay', value: 13500 },
        VC: { name: 'Saint-Vincent et les Grenadines', value: 800 },
        // VE: { name: 'Vénézuela', value: 3100 },
        VN: { name: 'Vietnam', value: 7000000 },
        WF: { name: 'Wallis et Futuna', value: 200 },
        YT: { name: 'Mayotte', value: 200 },
        ZA: { name: 'Afrique du Sud', value: 4500 },
    },
};

const HARDCODED_CURRENCIES: Record<string, string> = {
    AUD: 'AU$', // Australia
    BAM: 'KM', // Bosnia & H
    BHD: 'BD‌', // Bahrain
    CLP: 'CL$', // Chile
    COP: 'CO$‌', // Colombia
    IDR: 'Rp', // Indonesia
    ISK: 'kr‌', // Islande
    KWD: 'KD', // Kuwait
    MOP: 'MOP$‌', // Macao
    MUR: 'MURS', // Mauritius
    MXN: 'MEX$‌', // Mexico
    MYR: 'RM‌', // Malaysia
    NGN: '₦', // Nigeria
    PAB: 'B/.‌', // Panama
    PEN: 'S/.', // Peru
    QAR: 'QR', // Qatar
    RSD: 'din.‌', // Serbie
    THB: '฿', // Thailand
    TND: 'DT‌', // Tunisia
    TRY: 'TL', // Turquie
    UAH: '₴‌', // Ukraine
    UYU: '$U', // Uruguay
    XOF: 'CFA', // Cote d'Ivoire + Senegal
    ZAR: 'R', // South Africa
};

export const formatAddress = (address: Address | UserAddress) =>
    `${address.name ? address.name + ' - ' : ''}${
        address.lastName && !address.relayId ? address.lastName + ' ' + address.firstName + ', ' : ''
    }${address.address}, ${address.addressMore ? address.addressMore + ', ' : ''}${address.postcode} ${address.city}, ${
        address.country
    }`;

export const formatPrice = (parts: Intl.NumberFormatPart[], currency = '') => {
    const hardcodedCurrency = HARDCODED_CURRENCIES[currency];
    return parts
        .filter(part => (currency === 'EUR' ? part.type !== 'literal' : true))
        .map(part => (part.type === 'currency' && hardcodedCurrency ? { ...part, value: hardcodedCurrency } : part))
        .map(part => part.value)
        .join('');
};

export const ShippingModeDisplay = ({ shipping }: { shipping: ShippingMode }) => (
    <>
        {shipping.isShop && <FormattedMessage id="shipping.mode.shop" />}
        {!shipping.isShop && shipping.label}
    </>
);

export const getDefaultShippingMode = (shippingModes: ShippingMode[]) => {
    const availableShippingModes = shippingModes.filter(shipping => shipping.isAvailable);

    const defaultShippingMode =
        availableShippingModes.find(
            shipping => shipping.needsRelay === false && shipping.isShop === false && shipping.needsAddress === true
        ) ||
        availableShippingModes.find(shipping => shipping.price === 0) ||
        availableShippingModes.slice(0, 1).shift();

    return defaultShippingMode?.isAvailable ? defaultShippingMode : null;
};

export const formatFieldName = (name: string, formPart?: keyof FormData) =>
    `${formPart ? `${formPart}.` : ''}${name}` as FieldPath<FormData>;
