import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { OrderProduct } from '../../types/api';
import { getCroppedImageUrl } from '../../utils/cloudinary';

type Props = {
    display?: 'xs' | 'small' | 'normal';
    product: OrderProduct;
    onRemove: () => void;
    productUrl: string;
};

const OrderItemMultiProduct = ({ display = 'normal', product, onRemove, productUrl }: Props) => {
    return (
        <div className="c-order-checkout__item">
            <div className={classNames('o-grid o-grid--gutter-sm-bis u-pb-sm')}>
                <div className="o-grid__col o-grid__col--19 o-grid__col--20@md o-grid__col--20@md-plus">
                    <a className="c-order-checkout__label" href={productUrl} target="_blank" rel="noreferrer">
                        {product.label}
                    </a>
                </div>
                <div className="o-grid__col o-grid__col--5 o-grid__col--4@md-plus u-text-right">
                    <p className="c-order-checkout__price">
                        {product.crossedOutPrice && (
                            <>
                                <s className="u-color-gray-400">{product.totalCrossedOutDisplayPrice}</s>{' '}
                            </>
                        )}
                        {product.gift ? <FormattedMessage id="cart.promo.free" /> : product.totalDisplayPrice}
                    </p>
                    {display === 'normal' && (
                        <div className="u-none@sm">
                            <button className={'c-order-checkout__button'} type="button" onClick={onRemove}>
                                <FormattedMessage id="actions.remove_from_basket" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="o-grid o-grid--gutter-sm-bis">
                {product.multiProducts?.map((singleProduct, i) => (
                    <div
                        className={classNames(
                            'o-grid__col o-grid__col--22@sm',
                            display === 'normal' ? 'o-grid__col--10' : 'o-grid__col--22'
                        )}
                    >
                        <div className="o-grid o-grid--gutter-sm-bis">
                            <div
                                className={classNames(
                                    'o-grid__col',
                                    display === 'normal' ? 'o-grid__col--10' : 'o-grid__col--4'
                                )}
                            >
                                <div className="c-order-checkout__image">
                                    <img
                                        src={getCroppedImageUrl(singleProduct.imageUrl!, 200)}
                                        width="122"
                                        height="178"
                                        className="u-w-100"
                                        alt={singleProduct.label}
                                    />
                                </div>
                            </div>
                            <div className="o-grid__col o-grid__col--14">
                                <a
                                    className="c-order-checkout__label"
                                    href={productUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {singleProduct.label}
                                </a>
                                {singleProduct.colorLabel && (
                                    <ul className="c-order-checkout__list c-order-checkout__list--secondary u-mt-n">
                                        <li>{singleProduct.colorLabel}</li>
                                    </ul>
                                )}
                                <ul className="c-order-checkout__list u-mt-xs">
                                    {singleProduct.sizeLabel && (
                                        <li>
                                            <FormattedMessage id="cart.list.size" /> {singleProduct.sizeLabel}
                                        </li>
                                    )}
                                </ul>
                                {display === 'normal' && i === product.multiProducts!.length - 1 && (
                                    <div className="u-none@md u-none@md-plus u-mt-xs-sm">
                                        <button className={'c-order-checkout__button'} type="button" onClick={onRemove}>
                                            <FormattedMessage id="actions.remove_from_basket" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrderItemMultiProduct;
