import { CountryState } from '@sezane/front-components';
import React, { ReactNode, useContext, useState } from 'react';
import { useQuery } from 'react-query';

import { UserAddress } from '../types';
import {
    FETCH_USER_ADDRESSES_KEY,
    formatAddressFromCMStoOMS,
    isNewAddress,
    setUserAddressEmptyValues,
} from '../utils/address';
import apiClient from '../utils/api';
import { useAppContext } from './AppContext';
import { useAuth } from './AuthContext';
import { useCountry } from './CountryContext';

interface UserAddressesContextType {
    allUserAddresses: UserAddress[];
    filteredUserAddressesByCountry: UserAddress[];
    isLoadingAdresses?: boolean;
    defaultShippingAddress?: UserAddress;
    countryState?: CountryState;
    newAddress?: UserAddress;
    setCountryState: (countryState: CountryState) => void;
}

const UserAddressesContext = React.createContext<UserAddressesContextType>({
    allUserAddresses: [],
    filteredUserAddressesByCountry: [],
    isLoadingAdresses: false,
    countryState: undefined,
    setCountryState: () => {},
    newAddress: undefined,
});

export const UserAddressesProvider = ({ children }: { children: ReactNode }) => {
    const { brand, site } = useAppContext();
    const { keycloakId, user } = useAuth();
    const { country } = useCountry();
    const [countryState, setCountryState] = useState<CountryState | undefined>(undefined);

    const { data: addressesResponse, isLoading: isLoadingAdresses } = useQuery(
        [FETCH_USER_ADDRESSES_KEY],
        () => {
            const query: { brand: string; site: string; countryCode?: string } = {
                site: site.code!,
                brand,
            };

            return apiClient.getUserAddresses(keycloakId, query);
        },
        { enabled: !!keycloakId }
    );

    const userAddressEmptyValues = setUserAddressEmptyValues({ user, country, countryCode: country.countryISO });
    const userAddresses = addressesResponse?.data
        ? // Workaround to use front-components' form as we have a different phone field name in CMS and OMS endpoints
          [userAddressEmptyValues as UserAddress, ...addressesResponse?.data.map(add => formatAddressFromCMStoOMS(add))]
        : [userAddressEmptyValues as UserAddress];

    const filteredUserAddressesByCountry = userAddresses.filter(address => {
        return (
            address.countryCode === country.countryISO ||
            address.countryCode === countryState?.code ||
            isNewAddress(address)
        );
    });
    const defaultShippingAddress = filteredUserAddressesByCountry.find(userAddress => userAddress.isDefaultShipping);
    const newAddress = filteredUserAddressesByCountry.length === 1 ? filteredUserAddressesByCountry[0] : undefined;

    return (
        <UserAddressesContext.Provider
            value={{
                allUserAddresses: userAddresses,
                filteredUserAddressesByCountry,
                isLoadingAdresses,
                defaultShippingAddress,
                countryState,
                setCountryState,
                newAddress,
            }}
        >
            {children}
        </UserAddressesContext.Provider>
    );
};

export const useUserAddresses = () => {
    const userAddressesContext = useContext(UserAddressesContext);
    return userAddressesContext;
};
